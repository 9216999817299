import "./resourceEffectList.module.css?esbuild-css-modules-plugin-built";

export default new Proxy({"container":"resourceEffectList-module__container_C8PDIa1014"}, {
  get: function(source, key) {
    setTimeout(() => {
      window.__inject_03fc0329b6cd1e5b7b58adf4b9d30fd8c96fb6ae54edb74d1478929e288dc990__ && window.__inject_03fc0329b6cd1e5b7b58adf4b9d30fd8c96fb6ae54edb74d1478929e288dc990__();
    }, 0);
    return source[key];
  }
});
  ;
export const container = "resourceEffectList-module__container_C8PDIa1014";